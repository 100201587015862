// @ts-nocheck
import React, { Fragment } from 'react';
import styles from './style.module.scss';
import {
    isWeiXin,
    isZZCApp,
    isCrcApp,
    isRewardsApp,
    isTantuCrcApp,
    isCarRentalApp,
    isAndroid,
} from '@utils/native';
import {
    handleLogin,
    onPageShow,
    isOneOfMiniprograms,
    isOneOfQuickApp,
    listenerAppLoginStatus,
} from '@utils/native';
import { getUserId, get, set, getPartnerId } from '@utils/cookie';
import { hideCRCTopBar, hideOpenApp } from '@utils/tools';
import * as utilLogger from '@utils/logger';
import WarmDialog from '@common/WarmDialog';
import EasyImage from '@components/EasyImage';

/**
 * h5跳转app组件
 */
class OpenAppContainer extends React.Component {
    constructor(options = {}) {
        super(options);

        this.state = {
            isShowContainer: true,
            isShowLoginModal: false,
        };
    }

    componentDidMount() {
        // 设置url访问隐藏
        const isHideOpenAppUrl = hideOpenApp();
        if (isHideOpenAppUrl) {
            set('hideOpenApp', true);
        }
        // 不是租租车app，国内租车app，小程序， 分销商, url访问隐藏
        this.showThisComponents =
            !isCarRentalApp() &&
            !isOneOfMiniprograms() &&
            !isOneOfQuickApp() &&
            !hideCRCTopBar() &&
            !hideOpenApp() &&
            !get('hideOpenApp') &&
            !getPartnerId();

        // 直接跳转app, 首页：不传url，直接跳转原生首页
        if (this.showThisComponents && isAndroid()) {
            const protocol = 'zzczijia'; //
            const { targetUrl } = this.props;
            this.locationHref(this.formatTargetParams(targetUrl, protocol));
        }

        // 处理登录页返回（没登录：弹窗，有登录：刷新）
        const { shouldLogin } = this.props;

        if (shouldLogin) {
            // 监听登录返回
            listenerAppLoginStatus(response => {
                // 暂时无论怎么样，状态变就重新reload；
                window.location.reload();
            });

            // 没登录， 弹窗
            onPageShow(() => {
                setTimeout(() => {
                    if (!getUserId()) {
                        this.setState({
                            isShowLoginModal: true,
                        });
                    }
                }, 1000);
            });
        }
    }

    /**
     * 唤起APP，打开指定页面（H5页面或native页面）
     */
    openApp(targetPageUrl) {
        const { wx } = window;
        if (isWeiXin && wx) {
            wx.ready(() => {
                wx.invoke(
                    'launchApplication',
                    {
                        appID: 'wx1fb94dba3a9f9ec0',
                        parameter: `wechat_launch?scheme_url=${encodeURIComponent(
                            targetPageUrl
                        )}`,
                        extInfo: JSON.stringify({
                            url: targetPageUrl,
                        }),
                    },
                    response => {
                        console.log(response);
                        if (
                            response.err_msg === 'launchApplication:ok' ||
                            window.location.hostname === 'oia.zuzuche.com'
                        ) {
                            // window.location.href = `${targetPageUrl}&auto_redirect=0`;
                        }
                    }
                );
            });
        } else {
            this.locationHref(targetPageUrl);
        }
    }

    /**
     * TODO: 先保留，使用场景是业务页面要进入oia下载页
     * 打开下载页。下载页会自动尝试唤起APP，并打开指定页面
     */
    openDownloadPage = () => {
        const { targetUrl } = this.props;
        this.openApp(this.formatTargetParams(targetUrl));
        this.locationHref(this.formatDownloadParams(targetUrl));
    };

    handleToLogin() {
        handleLogin(location.href);
    }

    locationHref(url) {
        window.location.href = url;
    }

    // 格式化参数
    formatTargetParams(targetUrl, protocol = 'zzczijia') {
        return targetUrl
            ? `${protocol}://url?link=${encodeURIComponent(targetUrl)}`
            : `${protocol}://url`;
    }

    // 格式化参数
    formatDownloadParams(url) {
        return url
            ? `https://oia.zuzuche.com/?ref=${encodeURIComponent(url)}&cd=upu5je`
            : 'https://oia.zuzuche.com/?cd=upu5je';
    }

    render() {
        const { isShowContainer, isShowLoginModal } = this.state;

        return (
            <Fragment>
                {isShowContainer && this.showThisComponents && (
                    <div className={styles['open-app-container']}>
                        <EasyImage
                            className={styles['open-app-img']}
                            onClick={this.openDownloadPage}
                            src="https://zzccdn.zuzuche.com/irc-common/upload_static/42/15/6d19035552f6529d25d7c8cfe7abf5e0.png"
                            opt={{
                                fw: 750,
                                quality: 70,
                            }}
                        />
                        <div
                            className={styles['open-app-close']}
                            data-tj="顶部下载引导_点击_关闭按钮"
                            onClick={() => this.setState({ isShowContainer: false })}
                        />
                    </div>
                )}
                {isShowLoginModal && (
                    <WarmDialog
                        msg="需要登录才能访问本页面"
                        actionBtn="去登录"
                        onAction={this.handleToLogin}
                        onColoseDialog={() => this.setState({ isShowLoginModal: false })}
                    />
                )}
            </Fragment>
        );
    }
}

export default OpenAppContainer;
