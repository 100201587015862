// @ts-nocheck
import React, { memo } from 'react';
import { Dialog } from 'zzc-design-mobile';
import styles from './style.module.scss';


export default function WarmDialog(props) {
    const {
        msg,
        actionBtn,
        onAction,
        onColoseDialog
    } = props;

    return (
        <Dialog>
            <div className={styles['m-warm-dialog']}>
                <div className={styles['u-title']}>温馨提示</div>
                <div className={styles['u-msg']}>
                    {msg}
                </div>
                <div className={styles['m-btns-box']}>
                    <div
                        className={styles['m-btn-default']}
                        onClick={onAction}
                    >
                        {actionBtn}
                    </div>
                    <div className={styles['u-btn']} onClick={onColoseDialog}>
                        知道了
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
